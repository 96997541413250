import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/utils/layout";
import { RebassCard } from "../utils/styled";
import { Tiles, Flex } from 'rebass';
import { ThemeProvider } from "styled-components";
import theme from "../styles/theme";
import { Link } from "gatsby";
import SEO from "../components/utils/seo";
import { Contact } from "../components/contact";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Terms & conditions" mdxType="SEO" />
    <img style={{
      height: 300,
      marginBottom: 100
    }} src={require(`../images/orange/undraw_terms_lso0.svg`)} alt={"What_image"} />
    <h1>{`Terms & conditions:`}</h1>
    <br />
    <h3>{`First, a little bit about me:`}</h3>
    <p>{`My name is Alexandre Ramalho, and I am a full-stack software engineer currently working full time in
`}<a parentName="p" {...{
        "href": "https://vwds.pt"
      }}>{`Volkswagen Digital Solutions`}</a>{`.`}</p>
    <p>{`I like to learn, a lot. So I am always trying to find my next challenge.
I'm currently doing an `}<a parentName="p" {...{
        "href": "https://radialcor.pt"
      }}>{`E-commerce store for Radialcor`}</a>{` using Django and
Wagtail, because I've wanted to get more comfortable with Python, and Django is a highly reliable Web framework. I also
started learning more about the Jamstack and decided to give it a try, so I made this website using
`}<a parentName="p" {...{
        "href": "https://gatsbyjs.org"
      }}>{`Gatsby`}</a>{`. That's a little background for you. You can always snoop my
`}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/alexandre-francisco-ramalho/"
      }}>{`LinkedIn`}</a>{` to get to know a little bit more about my
education/professional experience.`}</p>
    <p>{`Now, the real thing, `}<strong parentName="p">{`what can I do for you?`}</strong></p>
    <p>{`Anything, quite literally, I don't see this as you buying a service from me (although it is),
I see this as you wanting someone to develop your projects, and I want some projects to develop. I'm also willing to do
a partnership with other developers if a project is appealing enough. With that
said, I am currently learning more about Jamstack/Machine Learning and plan to dive into Functional Programming after.
So, projects that allow me to use these technologies would be of my special interest :)`}</p>
    <p>{`The way this goes is the following:`}</p>
    <Flex flexWrap='wrap' mdxType="Flex">
    <RebassCard variant icon={1} mdxType="RebassCard">
    You get in contact with me, either through the
    <Link to="/#go" mdxType="Link"><i> contact form </i></Link>
    or through
    <a target="_blank" href="https://www.linkedin.com/in/alexandre-francisco-ramalho/"> <i>LinkedIn</i></a> with a project <i>suggestion</i>.
    </RebassCard>
    <RebassCard variant icon={2} mdxType="RebassCard">
    I evaluate its feasibility and my interest on it.
    </RebassCard>
    <RebassCard variant icon={3} mdxType="RebassCard">
    I contact you back either to tell you I'm not interested or that I am ready to proceed!
    </RebassCard>
    <RebassCard variant icon={4} mdxType="RebassCard">
    We then book a meeting and discuss prices, requirements and a rough timeline estimate.
    </RebassCard>
    <RebassCard variant icon={5} mdxType="RebassCard">
    When I have a prototype ready, you evaluate it, and either:
        <br />👎 You call it off (with no strings attached);
        <br />👍 You call it <b>on</b> and pay 50% of previously discussed price
    </RebassCard>
    <RebassCard variant icon={6} mdxType="RebassCard">
    I work some more on the project.
    </RebassCard>
    <RebassCard variant icon={7} mdxType="RebassCard">
    I deliver and you pay the final 50%.
    </RebassCard>
    <RebassCard variant icon={8} mdxType="RebassCard">
    😃 You're happy
    </RebassCard>
    <RebassCard variant icon={9} mdxType="RebassCard">
    I'm happy 😃
    </RebassCard>
    </Flex>
    <p>{`I hope I convinced you to give me a try! :)
If there was something you didn't understand in full please feel free to contact me, either through my social links
or through the contact form`}</p>
    <Link to="/#go" mdxType="Link">Go back to the homepage</Link>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      